@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom utility for hiding scrollbars while maintaining functionality */
@layer utilities {
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

:root {
  --dm2buy-pink: hsl(323.2, 82%, 54.3%);
  --border: hsla(0, 0%, 59.2%, 0.4);
  --bg-color: #ffffff;
  --max-width: 520px;
}
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Inter,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */

  /* height: 100%; */

  /* overflow-y: hidden; */

  /* overscroll-behavior-y: none; */
}
a {
  color: inherit;
  text-decoration: none;
}
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  margin-left: 20px;
}

.carousel {
  margin-bottom: 24px !important;
  height: 118vw !important;
}

/* @media screen and (max-width: 380px) {
  .carousel {
    margin-bottom: 24px !important;
    height: 132vw !important;
  }
} */

@media screen and (min-width: 520px) {
  .carousel {
    margin-bottom: 24px !important;
    height: 465px !important;
  }
}

/* .carousel .control-prev.control-arrow::before {
  border-right: 8px solid #000 !important;
  position: absolute;
  bottom: 8px;
  z-index: 99;
} */
.carousel .slide img {
  width: auto;
  /* border-radius: 20px; */
  object-fit: contain;
}

.carousel .slider-wrapper {
  /* border-radius: 20px; */
}

.carousel .control-next.control-arrow::before {
  border-left: 8px solid #333333 !important;
  /* position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 99; */
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #333333 !important;
}

.carousel.carousel-slider .control-arrow {
  background: none !important;
  transition: none !important;
}
.control-dots {
  position: absolute;
  top: calc(100% - 30px) !important;
  margin: 0 !important;
  /* top: 416px; */
}
.control-dots > li {
  margin: 15px 10px !important;
}
li.slide > div {
  height: 100%;
}
.control-dots > li {
  margin: 15px 10px !important;
}
li.slide > div {
  height: 100%;
}
.underline-button {
  text-decoration: underline;
}

footer a {
  margin: 1.5rem 1rem 0.8rem 0;
  opacity: 0.6;
}
footer a:last-child {
  margin-right: 0px;
}
